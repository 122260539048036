/* stylelint-disable-next-line a11y/font-size-is-readable */
html {
    font-size: 13px;
    line-height: 1.4;

    @media (--min-sm) {
        font-size: calc(13px + 3 * ((100vw - 300px) / 1200));
    }

    @media (--min-lg) {
        font-size: 16px;
    }
}

body {
    width: 100%;
    position: relative;
}
