:root {
    --form_background-color: transparent;
    --form_text-color: var(--color-white);
    --form_focus-color: var(--color-blue-lighten-3);
    --form_border-color: var(--color-white);
    --form_label-color: var(--color-white);
    --form_placeholder-color: var(--color-white);
    --form_maxlength-color: var(--color-white);
    --form_readonly-color: var(--color-white);
}
