.container {
    width: 90vw;
    max-width: 1440px;
    margin: 0 auto;
}

.text-blue {
    color: hsl(var(--color-blue-lighten-3));
}

.text-red {
    color: hsl(var(--color-red));
}

.text-yellow {
    color: hsl(var(--color-yellow));
}

.paragraph {
    font-size: 1.25rem;
    text-align: center;

    @media (--min-sm) {
        margin: calc(2 * var(--gap)) auto;
    }

    @media (--min-md) {
        margin: calc(4 * var(--gap)) auto;
    }

    @media (--min-lg) {
        margin: calc(6 * var(--gap)) auto;
    }
}

.sr-only {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    position: absolute !important;
    overflow: hidden !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.error-animation {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}
