.plyr {
    min-width: 200px;
    max-width: 100%;
    margin-bottom: calc(var(--gap) * 5);
    position: relative;
    font-family: 'Helvetica', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;

    &:fullscreen {
        width: 100%;
        height: 100%;
        margin: 0;
        background: hsl(var(--color-black));
        border-radius: 0 !important;
    }

    audio,
    video {
        width: 100%;
        height: auto;
        vertical-align: middle;
        border-radius: inherit;
    }

    [data-plyr = 'airplay'],
    [data-plyr = 'captions'],
    [data-plyr = 'fullscreen'],
    [data-plyr = 'pip'] {
        display: none;
    }

    [hidden] {
        display: none !important;
    }
}

.plyr__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

    &:empty {
        display: none;
    }

    .plyr__progress__container {
        min-width: 0;
        flex: 1;
    }

    .plyr__controls__item {

        &:first-child {
            margin-right: auto;
            margin-left: 0;
        }

        &.plyr__progress__container,
        &.plyr__time {
            padding: calc(0.5 * var(--gap));
        }
    }
}

.plyr__control {
    padding: calc(0.5 * var(--gap));
    position: relative;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    border-radius: var(--global-border-radius);
    border: 0;

    &:hover,
    &:focus {
        color: hsl(var(--color-blue));
    }

    &::after,
    &::before,
    &.plyr__control--pressed .icon--not-pressed,
    &.plyr__control--pressed .label--not-pressed,
    &:not(.plyr__control--pressed) .icon--pressed,
    &:not(.plyr__control--pressed) .label--pressed {
        display: none;
    }

    &.plyr__tab-focus,
    &[aria-expanded = 'true'] {
        color: hsl(var(--color-blue));
        box-shadow: 0 0 0 3px hsla(var(--color-blue), 0.25);
    }

    svg {
        width: 18px;
        height: 18px;
        display: block;
        fill: currentcolor;
        pointer-events: none;
    }
}

.plyr__control--overlaid {
    --padding: 10px;
    --scale: 0.75;
    padding: var(--padding);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: var(--zindex-plyr-controls);
    transform: translate(-50%, -50%);
    transition: border-color var(--transition-in) var(--transition-timing);
    border-radius: 50%;
    border: 2px solid hsla(var(--color-white), 0.75);

    &:focus,
    &:hover,
    &.plyr__tab-focus {
        border-color: hsla(var(--color-blue), 0.75);

        svg {
            --scale: 1.5;
        }
    }

    svg {
        position: relative;
        left: 2px;
        fill: hsla(var(--color-white), 0.75);
        transform: scale(var(--scale));
        transition: transform var(--transition-in) var(--transition-timing);
    }

    @media (--min-sm) {
        --padding: 20px;
        --scale: 1;
    }

    @media (--min-md) {
        --padding: 30px;
        --scale: 1.5;
    }

    @media (--min-lg) {
        --padding: 40px;
        --scale: 2;
    }
}

.plyr__poster {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background-color: hsl(var(--color-black));
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    transition: opacity var(--transition-in) var(--transition-timing);
}

.plyr__tooltip {
    margin-bottom: 10px;
    padding: calc(0.5 * var(--gap)) var(--gap);
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 2;
    opacity: 0;
    background: hsla(var(--color-black), 0.25);
    color: hsl(var(--color-white));
    white-space: nowrap;
    transform: translate(-50%, 10px) scale(0.8);
    transform-origin: 50% 100%;
    transition-property: transform, opacity;
    transition-duration: var(--transition-out);
    transition-timing-function: var(--transition-timing);
    pointer-events: none;
    border-radius: var(--global-border-radius);

    &::before {
        width: 0;
        height: 0;
        position: absolute;
        bottom: -4px;
        left: 50%;
        z-index: 2;
        content: '';
        transform: translateX(-50%);
        border-width: 4px 4px 0;
        border-style: solid;
        border-color: hsla(var(--color-black), 0.25) transparent transparent;
    }
}

.plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control:focus .plyr__tooltip,
.plyr__tooltip--visible {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
}

.plyr__video-wrapper {
    position: relative;
    z-index: 0;
    overflow: hidden;
    background: hsl(var(--color-black));
    border-radius: inherit;
}

.plyr__progress {
    margin-right: var(--gap);
    position: relative;
    left: calc(0.5 * var(--gap));

    .plyr__tooltip {
        left: 0;
    }
}

.plyr__progress [type = 'range'],
.plyr__progress__buffer {
    width: calc(100% + var(--gap));
    margin-right: calc(-1 * (0.5 * var(--gap)));
    margin-left: calc(-1 * (0.5 * var(--gap)));
}

.plyr__progress__buffer {
    height: 4px;
    margin-top: -2.5px;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 0;
    appearance: none;
    background: none;
    border-radius: 100px;
    border: 0;

    &::-webkit-progress-bar {
        background: hsla(var(--color-white), 0.15);
        border-radius: 4px;
    }

    &::-webkit-progress-value {
        min-width: 12px;
        background: hsla(var(--color-white), 0.15);
        transition: width var(--transition-in) var(--transition-timing);
        border-radius: 4px;
    }

    &::-moz-progress-bar {
        min-width: 12px;
        background: hsla(var(--color-white), 0.15);
        transition: width var(--transition-in) var(--transition-timing);
        border-radius: 4px;
    }

    &::-ms-fill {
        min-width: 12px;
        background: hsla(var(--color-white), 0.15);
        transition: width var(--transition-in) var(--transition-timing);
        border-radius: 4px;
    }
}

.plyr--video {
    overflow: hidden;
    background: hsl(var(--color-black));
    transition: box-shadow var(--transition-in) var(--transition-timing);
    border-radius: 12px;
    border: 2px solid hsla(var(--color-white), 0.05);

    &.plyr--loading {

        .plyr__progress__buffer {
            background-color: hsla(var(--color-white), 0.15);
        }
    }

    &.plyr--playing {
        box-shadow: 0 0 200px hsla(var(--color-blue), 0.15);
    }

    &.plyr--hide-controls {

        .plyr__controls {
            opacity: 0;
            transform: translateY(100%);
            pointer-events: none;
        }
    }

    .plyr__controls {
        padding: calc(0.5 * var(--gap));
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: var(--zindex-plyr-controls);
        background: linear-gradient(hsla(var(--color-black), 0), hsla(var(--color-black), 0.5));
        transition-property: opacity, transform;
        transition-duration: var(--transition-in);
        transition-timing-function: var(--transition-timing);
    }

    .plyr__progress__buffer {
        color: hsla(var(--color-white), 0.25);
        box-shadow: 0 1px 1px hsla(var(--color-black), 0.15);
    }
}

.plyr--playing {

    .plyr__control--overlaid {
        opacity: 0;
        visibility: hidden;
    }
}

.plyr--stopped {

    &.plyr__poster-enabled {

        .plyr__poster {
            opacity: 1;
        }
    }
}

.plyr--loading {

    .plyr__progress__buffer {
        background-image: linear-gradient(-45deg, hsla(var(--color-white-darken-5), 0.6) 25%, transparent 25%, transparent 50%, hsla(var(--color-white-darken-5), 0.6) 50%, hsla(var(--color-white-darken-5), 0.6) 75%, transparent 75%, transparent);
        background-repeat: repeat-x;
        background-size: 25px 25px;
        color: transparent;
    }
}

.plyr--airplay-supported [data-plyr = 'airplay'],
.plyr--captions-enabled [data-plyr = 'captions'],
.plyr--fullscreen-enabled [data-plyr = 'fullscreen'],
.plyr--pip-supported [data-plyr = 'pip'] {
    display: inline-block;
}

.plyr--full-ui {

    a,
    button,
    input,
    label {
        touch-action: manipulation;
    }

    [type = 'range'] {
        width: 100%;
        height: 1rem;
        margin: 0;
        padding: 0;
        display: block;
        position: relative;
        z-index: 2;
        appearance: none;
        color: hsl(var(--color-white));
        border-radius: 100px;
        border: 0;

        &::-webkit-slider-runnable-track,
        &::-moz-range-track,
        &::-ms-track,
        &::-ms-fill-upper,
        &::-ms-fill-lower {
            height: 4px;
            background: none;
            user-select: none;
            border-radius: var(--global-border-radius);
            border: 0;
        }

        &::-webkit-slider-thumb,
        &::-moz-range-thumb,
        &::-ms-thumb {
            width: 1rem;
            height: 1rem;
            margin-top: calc((1rem - 4px) / -2);
            position: relative;
            appearance: none;
            background: hsl(var(--color-white));
            transition: all var(--transition-out) var(--transition-timing);
            border-radius: 50%;
            border: 0;
        }

        &::-moz-range-progress {
            height: 4px;
            border-radius: var(--global-border-radius);
        }

        &::-ms-tooltip {
            display: none;
        }

        &::-moz-focus-outer {
            border: 0;
        }

        &.plyr__tab-focus {

            &::-webkit-slider-runnable-track {
                box-shadow: 0 0 0 5px hsla(var(--color-white), 0.5);
                outline: 0;
            }

            &::-moz-range-track {
                box-shadow: 0 0 0 5px hsla(var(--color-white), 0.5);
                outline: 0;
            }

            &::-ms-track {
                box-shadow: 0 0 0 5px hsla(var(--color-white), 0.5);
                outline: 0;
            }
        }
    }

    &.plyr--video,
    &.plyr--audio {

        [type = 'range'] {

            &:active {

                /* stylelint-disable-next-line max-nesting-depth */
                &::-webkit-slider-thumb {
                    box-shadow: 0 1px 1px hsla(var(--color-black), 0.15), 0 0 0 1px hsla(var(--color-black), 0.2), 0 0 0 3px hsla(var(--color-white), 0.5);
                }

                /* stylelint-disable-next-line max-nesting-depth */
                &::-moz-range-thumb {
                    box-shadow: 0 1px 1px hsla(var(--color-black), 0.15), 0 0 0 1px hsla(var(--color-black), 0.2), 0 0 0 3px hsla(var(--color-white), 0.5);
                }

                /* stylelint-disable-next-line max-nesting-depth */
                &::-ms-thumb {
                    box-shadow: 0 1px 1px hsla(var(--color-black), 0.15), 0 0 0 1px hsla(var(--color-black), 0.2), 0 0 0 3px hsla(var(--color-white), 0.5);
                }
            }

            &::-webkit-slider-runnable-track {
                background-color: hsla(var(--color-white), 0.25);
            }

            &::-moz-range-track {
                background-color: hsla(var(--color-white), 0.25);
            }

            &::-ms-track {
                background-color: hsla(var(--color-white), 0.25);
            }
        }
    }
}

.plyr__volume {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;

    [type = 'range'] {
        padding: calc(0.5 * var(--gap));
        position: relative;
        z-index: 2;
    }

    @media (--min-sm) {
        max-width: 90px;
    }

    @media (--min-md) {
        max-width: 110px;
    }
}

.plyr--is-ios {

    &.plyr--vimeo {

        [data-plyr = 'mute'] {
            display: none !important;
        }
    }

    .plyr__volume {
        display: none !important;
    }
}

.plyr:fullscreen,
.plyr--fullscreen-fallback {

    &.plyr--vimeo {

        .plyr__video-wrapper {
            height: 0;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.plyr--hide-controls {
        cursor: none;
    }

    video {
        height: 100%;
    }

    .plyr__video-wrapper {
        height: 100%;
        position: static;
    }

    .plyr__control {

        .icon--exit-fullscreen {
            display: block;

            + svg {
                display: none;
            }
        }
    }
}

.plyr--fullscreen-fallback {
    width: 100%;
    height: 100%;
    margin: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background: hsl(var(--color-black));
    border-radius: 0 !important;
}

.plyr--no-transition {
    transition: none !important;
}

.plyr__sr-only {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    position: absolute !important;
    overflow: hidden;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
}
