@font-face {
    font-family: 'Helvetica';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/helvetica.woff2') format('woff2');
}

@font-face {
    font-family: 'Helvetica Bold';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/helvetica-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Avenir Bold';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/avenir-bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Avenir UltraLight';
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src: url('/fonts/avenir-ultralight.woff2') format('woff2');
}
