.sales {

    &.main {
        width: 100vw;
        min-height: 100vh;
    }

    .header {
        width: 100%;
        height: 10vh;
        min-height: 150px;
        max-height: var(--global-header-height);
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        @media (--max-md) {
            width: 100%;
            padding: 0 calc(2 * var(--gap));
        }
    }

    .logo {
        max-height: 4rem;
    }

    .section {
        width: 75vw;
        max-width: 900px;
        margin: 0 auto;
        padding: calc(2 * var(--gap)) 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;

        h2 {
            margin-bottom: calc(2 * var(--gap));
            font-size: 1.75rem;
            text-align: left;
        }

        p {
            margin-bottom: calc(1.5 * var(--gap));

            &:first-of-type {
                text-align: center;
            }

            &:last-of-type {
                margin-top: 3rem;
                margin-bottom: 0;
                font-style: italic;
            }
        }

        ul {
            margin-bottom: 1.5rem;
            padding-left: 1.5rem;
            list-style-type: square;
        }

        @media (--max-sm) {
            width: 100%;
            padding-right: var(--gap);
            padding-left: var(--gap);
        }
    }
}
