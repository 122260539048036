:root {
    --color-blue-lighten-5: 208, 70%, 60%;         /* #529ee0 */
    --color-blue-lighten-4: 208, 70%, 56%;         /* #4094dd */
    --color-blue-lighten-3: 208, 70%, 52%;         /* #2f8ada */
    --color-blue-lighten-2: 208, 70%, 48%;         /* #2580d0 */
    --color-blue-lighten-1: 208, 70%, 44%;         /* #2275bf */
    --color-blue: 208, 70%, 40%;                   /* #1f6bad */
    --color-blue-darken-1: 208, 70%, 36%;          /* #1c609c */
    --color-blue-darken-2: 208, 70%, 32%;          /* #18558b */
    --color-blue-darken-3: 208, 70%, 28%;          /* #154b79 */
    --color-blue-darken-4: 208, 70%, 24%;          /* #124068 */
    --color-blue-darken-5: 208, 70%, 20%;          /* #0f3557 */
    --color-green-lighten-5: 120, 93%, 56%;        /* #26f726 */
    --color-green-lighten-4: 120, 93%, 52%;        /* #13f613 */
    --color-green-lighten-3: 120, 93%, 48%;        /* #09ec09 */
    --color-green-lighten-2: 120, 93%, 44%;        /* #08d908 */
    --color-green-lighten-1: 120, 93%, 40%;        /* #07c507 */
    --color-green: 120, 93%, 36%;                  /* #06b106 */
    --color-green-darken-1: 120, 93%, 32%;         /* #069d06 */
    --color-green-darken-2: 120, 93%, 28%;         /* #058a05 */
    --color-green-darken-3: 120, 93%, 24%;         /* #047604 */
    --color-green-darken-4: 120, 93%, 20%;         /* #046204 */
    --color-green-darken-5: 120, 93%, 16%;         /* #034f03 */
    --color-yellow-lighten-5: 46, 80%, 68%;        /* #efd16b */
    --color-yellow-lighten-4: 46, 80%, 64%;        /* #eecb59 */
    --color-yellow-lighten-3: 46, 80%, 60%;        /* #ecc546 */
    --color-yellow-lighten-2: 46, 80%, 56%;        /* #eabf34 */
    --color-yellow-lighten-1: 46, 80%, 52%;        /* #e8b921 */
    --color-yellow: 46, 80%, 48%;                  /* #deaf17 */
    --color-yellow-darken-1: 46, 80%, 44%;         /* #cba115 */
    --color-yellow-darken-2: 46, 80%, 40%;         /* #b99213 */
    --color-yellow-darken-3: 46, 80%, 36%;         /* #a68311 */
    --color-yellow-darken-4: 46, 80%, 32%;         /* #947510 */
    --color-yellow-darken-5: 46, 80%, 28%;         /* #81660e */
    --color-red-lighten-5: 1, 79%, 68%;            /* #ee6f6d */
    --color-red-lighten-4: 1, 79%, 64%;            /* #ec5d5b */
    --color-red-lighten-3: 1, 79%, 60%;            /* #ea4b48 */
    --color-red-lighten-2: 1, 79%, 56%;            /* #e73936 */
    --color-red-lighten-1: 1, 79%, 52%;            /* #e52724 */
    --color-red: 1, 79%, 48%;                      /* #db1d1a */
    --color-red-darken-1: 1, 79%, 44%;             /* #c91b18 */
    --color-red-darken-2: 1, 79%, 40%;             /* #b71815 */
    --color-red-darken-3: 1, 79%, 36%;             /* #a41613 */
    --color-red-darken-4: 1, 79%, 32%;             /* #921311 */
    --color-red-darken-5: 1, 79%, 28%;             /* #80110f */
    --color-brown: 20, 34%, 49%;                   /* #a97053 */
    --color-white: 0, 0%, 98%;                     /* #f2f2f2 */
    --color-white-darken-1: 0, 0%, 92%;            /* #ebebeb */
    --color-white-darken-2: 0, 0%, 88%;            /* #e0e0e0 */
    --color-white-darken-3: 0, 0%, 84%;            /* #d6d6d6 */
    --color-white-darken-4: 0, 0%, 80%;            /* #ccc */
    --color-white-darken-5: 0, 0%, 76%;            /* #c2c2c2 */
    --color-black-lighten-5: 0, 0%, 20%;           /* #333333 */
    --color-black-lighten-4: 0, 0%, 16%;           /* #292929 */
    --color-black-lighten-3: 0, 0%, 12%;           /* #1f1f1f */
    --color-black-lighten-2: 0, 0%, 8%;            /* #141414 */
    --color-black-lighten-1: 0, 0%, 4%;            /* #0a0a0a */
    --color-black: 0, 0%, 0%;                      /* #000000 */
    --color-gray-lighten-5: 0, 0%, 58%;            /* #7c7979 */
    --color-gray-lighten-4: 0, 0%, 54%;            /* #716f6f */
    --color-gray-lighten-3: 0, 0%, 50%;            /* #676565 */
    --color-gray-lighten-2: 0, 0%, 46%;            /* #5d5b5b */
    --color-gray-lighten-1: 0, 0%, 42%;            /* #525151 */
    --color-gray: 0, 0%, 38%;                      /* #484747 */
}
