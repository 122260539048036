::selection {
    background: hsl(var(--global-text));
    color: hsl(var(--global-background));
}

::-webkit-scrollbar {
    width: 0.75rem;
}

::-webkit-scrollbar-track {
    background: hsl(var(--color-black));
}

::-webkit-scrollbar-thumb {
    background: hsl(var(--color-gray));
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:focus {
    background: hsl(var(--color-gray));
}
