h1 {
    margin: calc(2 * var(--gap)) 0;
    font-family: 'Avenir UltraLight', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    font-size: 4rem;
    text-align: center;

    @media (--min-sm) {
        font-size: 4.25rem;
    }

    @media (--min-md) {
        font-size: 4.5rem;
    }

    @media (--min-lg) {
        font-size: 4.75rem;
    }
}

h2 {
    margin-bottom: var(--gap);
    font-family: 'Avenir Bold', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    font-size: 1.75rem;
    line-height: 1.2;
    text-align: center;

    @media (--min-sm) {
        font-size: 2rem;
    }

    @media (--min-md) {
        font-size: 2.25rem;
    }

    @media (--min-lg) {
        font-size: 2.5rem;
    }
}

strong {
    font-family: 'Helvetica Bold', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;
}

small {
    font-size: 80%;
}

a[href ^= 'tel'] {
    text-decoration: none;
}

blockquote {
    font-style: italic;
}
